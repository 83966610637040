export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    salesOrder: {},
    selectedAddressType: null,
    selectedAddress: {},
    selectedPaymentMethod: {},
    paymentMethods: [],
    resultAction: {},
  }),

  actions: {
    setSalesOrder(obj) {
      this.salesOrder = obj;
    },
    getPaymentMethods({ commit, state }) {
      return useAPI("/api/front/payment-methods")
        .then(({ data }) => {
          this.paymentMethods = data;

          return data;
        })
        .catch(() => {
          console.error("[checkout] payment methods load error");
        });
    },
    setSelectedPaymentMethod(pm) {
      this.selectedPaymentMethod = pm;
    },
    setSelectedAddressType(type) {
      this.selectedAddressType = type;
    },
    setSelectedAddress(addr) {
      this.selectedAddress = addr;
    },
    checkout(data) {
      return useAPI(
        "/api/front/sales-orders/checkout",
        {
          method: 'post',
          body: {
            payment_method_id:
              typeof data.payment_method_id !== "undefined"
                ? data.payment_method_id
                : this.selectedPaymentMethod.id,
            check_terms: data.check_terms,
            address_id: data.address_id,
            address_type: data.address_type,
          },
          headers: {
            "X-Cart": this.$cookies.get(this.$config.CART_COOKIE_NAME),
          },
        }
      )
        .then(({ data }) => {
          this.resultAction = data;
        });
    },
    get(code) {
      return useAPI(`api/front/sales-orders/${code}`, code)
        .then(({ data }) => {
          this.salesOrder = data;
        })
        .catch(() => {
          console.error("[checkout] payment methods load error");
        });
    },
  },

  getters: {
    getSalesOrder: (state) => state.salesOrder,
    getSelectedPaymentMethod: (state) => state.selectedPaymentMethod,
    getSelectedAddressType: (state) => state.selectedAddressType,
    getSelectedAddress: (state) => state.selectedAddress,
    getPaymentMethods: (state) => state.paymentMethods,
    getResultAction: (state) => state.resultAction,
  },
});